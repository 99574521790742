.mapboxgl-canvas-container {
	cursor: default !important;
}

.mapboxgl-popup-content {
	background-color: var(--theme-color-dark) !important;
	border-radius: 0;
	box-shadow: none;
	padding: 5px;
}

.mapboxgl-popup-tip {
	display: none !important;
}
