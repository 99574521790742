.mobile-navigation {
	-webkit-overflow-scrolling: touch;
	background-color: var(--color-white);
	box-sizing: border-box;
	display: none;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: var(--space-xsmall);
	position: fixed;
	top: var(--site-header-height-small);
	left: 0;
	width: 100%;
	height: calc(100vh - var(--site-header-height-small));
	z-index: 98;
}

@media (--mq-up-to-medium) {
	#mobile-navigation-toggle:checked ~ .mobile-navigation {
		display: block;
	}
}

.l-styleguide .mobile-navigation {
	position: relative;
}

.mobile-navigation__inner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	min-height: 100%;
}

.mobile-navigation__bottom,
.mobile-navigation__top {
	width: 100%;
}

.mobile-navigation__top:not(:last-child):not(:only-child) {
	margin-bottom: var(--space-small);
}

.mobile-navigation__bottom {
	color: rgb(0 0 0 / 0.5);
}

.mobile-navigation__navigation-container {
	border-bottom: 1px solid var(--color-grey);
	padding-bottom: var(--space-xsmall);
}

.mobile-navigation__navigation-container:not(:last-child):not(:only-child) {
	margin-bottom: var(--space-xsmall);
}

.mobile-navigation__navigation:not(:last-child):not(:only-child) {
	margin-bottom: var(--space-xsmall);
}

@media (--mq-from-medium) {
	.mobile-navigation__navigation:not(:last-child):not(:only-child) {
		margin-bottom: 2.875rem;
	}
}

.mobile-navigation__contact-row.mobile-navigation__contact-row--social-links {
	padding-top: 0.375rem;
}

.mobile-navigation__contact-row:not(:last-child):not(:only-child) {
	margin-bottom: var(--space-xxsmall);
}

.mobile-navigation__contact-row a {
	color: currentColor;
}
