.banner {
	box-sizing: border-box;
	color: var(--theme-color);
	font-size: 1.25rem;
	line-height: 1.25;
	padding-right: var(--space-xsmall);
	padding-left: var(--space-xsmall);
	text-align: center;
	width: 100%;
}

@media (--mq-from-medium) {
	.banner {
		font-size: 1.375rem;
		line-height: 1.3636;
	}
}

.banner__wrapper {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	max-width: 500px;
}
