* {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: var(--font-regular);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.3125;
}

@media (--mq-from-wide) {
	body {
		font-size: 1.125rem;
		line-height: 1.4444;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-regular);
	font-weight: 400;
	margin-top: var(--space-small);
	margin-bottom: var(--space-xsmall);
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h1,
.type-a {
	font-size: 1.5625rem;
	line-height: 1.2;
}

@media (--mq-from-medium) {
	h1,
	.type-a {
		font-size: 1.875rem;
		line-height: 1.3333;
	}
}

h2,
.type-b {
	font-size: 1.25rem;
	line-height: 1.25;
}

@media (--mq-from-medium) {
	h2,
	.type-b {
		font-size: 1.375rem;
		line-height: 1.3636;
	}
}

@media (--mq-from-large) {
	h2,
	.type-b {
		font-size: 1.5625rem;
		line-height: 1.32;
	}
}

h3,
.type-c {
	font-size: 1.25rem;
	line-height: 1.1;
	text-transform: uppercase;
}

@media (--mq-from-medium) {
	h3,
	.type-c {
		font-size: 1.375rem;
		line-height: 1.3636;
	}
}

@media (--mq-from-large) {
	h3,
	.type-c {
		font-size: 1.5625rem;
		line-height: 1.32;
	}
}

h4,
.type-d {
	font-size: 1rem;
	line-height: 1.31;
	text-transform: uppercase;
}

@media (--mq-from-large) {
	h4,
	.type-d {
		font-size: 1.125rem;
		line-height: 1.44;
	}
}

h5,
h6,
.type-e,
.type-f {
	font-size: 0.875rem;
	line-height: 1.57;
	text-transform: uppercase;
}

@media (--mq-from-large) {
	h5,
	h6,
	.type-e,
	.type-f {
		font-size: 1rem;
		line-height: 1.63;
	}
}

h6,
.type-f {
	opacity: 0.52;
}

p {
	margin-top: 0;
	margin-bottom: 0.9375rem;
}

@media (--mq-from-medium) {
	p {
		margin-bottom: var(--space-xsmall);
	}
}

p:only-child,
p:last-child {
	margin-bottom: 0;
}

a:not([class]),
p a {
	color: currentColor;
	transition: color 96ms linear;
}

a:focus:not([class]),
p a:focus {
	outline: 0;
}

a:focus:not([class]),
p a:focus,
a:hover:not([class]),
p a:hover {
	color: var(--theme-color-dark);
}

.u-text-light a:focus:not([class]),
.u-text-light p a:focus,
.u-text-light a:hover:not([class]),
.u-text-light p a:hover {
	color: var(--theme-color);
}

b,
i,
em,
strong {
	font-style: normal;
	font-weight: 400;
}

i,
em {
	font-style: italic;
}

blockquote {
	display: inline-block;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 0.9375rem;
	margin-left: 0;
	width: 100%;
}

@media (--mq-from-medium) {
	blockquote {
		margin-bottom: var(--space-xsmall);
	}
}

blockquote > p:first-child {
	font-size: 1.5625rem;
	line-height: 1.24;
	margin-bottom: var(--space-xxsmall);
}

@media (--mq-from-medium) {
	blockquote > p:first-child {
		font-size: 2.25rem;
		line-height: normal;
	}
}

@media (--mq-from-large) {
	blockquote > p:first-child {
		font-size: 3.125rem;
		line-height: 1.18;
		margin-bottom: var(--space-xsmall);
	}
}

blockquote > p:not(:first-child) {
	font-size: 1rem;
	line-height: 1.31;
}

@media (--mq-from-medium) {
	blockquote > p:not(:first-child) {
		font-size: 1.25rem;
		line-height: normal;
	}
}

@media (--mq-from-large) {
	blockquote > p:not(:first-child) {
		font-size: 1.375rem;
		line-height: 1.18;
	}
}
