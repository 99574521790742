.form-media {
	width: 100%;
}

.form-media__wrapper {
	overflow: hidden;
	padding-bottom: 100%;
	position: relative;
	width: 100%;
	height: auto;
}

.form-media__asset {
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.form-media__asset {
		object-fit: cover;
		top: auto;
		left: auto;
		transform: none;
		height: 100%;
	}
}
