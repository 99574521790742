@media (--mq-up-to-small) {
	.team-member-banner {
		max-width: 420px;
	}
}

@media (--mq-up-to-medium) {
	.team-member-banner {
		margin-right: auto;
		margin-left: auto;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.team-member-banner {
		max-width: 540px;
	}
}

@media (--mq-from-small) {
	.team-member-banner {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
	}
}

.team-member-banner__data {
	flex-grow: 1;
}

.team-member-banner__title {
	color: var(--theme-color);
}

.team-member-banner__meta {
	margin-top: var(--space-small);
}

.team-member-banner__media {
	margin-top: calc(var(--space-small) * -1);
	width: 100%;
}

@media (--mq-up-to-small) {
	.team-member-banner__media {
		margin-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-small) {
	.team-member-banner__media {
		flex-grow: 0;
		flex-shrink: 0;
		margin-top: calc(var(--space-xlarge) * -1);
		margin-left: var(--space-small);
		width: 240px;
	}
}

@media (--mq-from-wide) {
	.team-member-banner__media {
		margin-top: -120px;
		width: 290px;
	}
}

.team-member-banner__picture {
	border-radius: var(--space-xxsmall);
	display: block;
	overflow: hidden;
	position: relative;
	transform: rotate(4deg);
	z-index: 10;
}

@media (--mq-from-small) {
	.team-member-banner__picture {
		border-radius: var(--space-xsmall);
	}
}
