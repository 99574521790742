.property-grid {
	position: relative;
	width: 100%;
}

.property-grid__wrapper {
	width: 100%;
}

@media (--mq-from-small) {
	.property-grid__wrapper {
		box-sizing: border-box;
		padding-right: var(--space-xsmall);
		padding-left: var(--space-xsmall);
	}
}

@media (--mq-from-xlarge) {
	.property-grid__wrapper {
		margin-right: auto;
		margin-left: auto;
		max-width: 1680px;
	}
}

.property-grid__row {
	position: relative;
}

@media (--mq-from-small) {
	.property-grid__row {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.property-grid__row.property-grid__row--call-to-action {
	justify-content: flex-end;
}

@media (--mq-from-small) {
	.property-grid__row:not(.property-grid__row--call-to-action):not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.property-grid__row:not(.property-grid__row--call-to-action):not(:last-child) {
		margin-bottom: var(--space-medium);
	}
}

@media (--mq-from-wide) {
	.property-grid__row:not(.property-grid__row--call-to-action):not(:last-child) {
		margin-bottom: var(--space-large);
	}
}

@media (--mq-from-small) {
	.property-grid__row.property-grid__row--call-to-action:not(:first-child) {
		margin-top: calc(var(--space-small) * -1);
	}
}

@media (--mq-from-medium) {
	.property-grid__row.property-grid__row--call-to-action:not(:first-child) {
		margin-top: calc(var(--space-medium) * -1);
	}
}

@media (--mq-from-wide) {
	.property-grid__row.property-grid__row--call-to-action:not(:first-child) {
		margin-top: calc(var(--space-large) * -1);
	}
}

.property-grid__scribble {
	position: absolute;
	right: var(--space-large);
	bottom: 0;
}

@media (--mq-up-to-medium) {
	.property-grid__scribble {
		display: none;
	}
}

.property-grid__scribble .shape-3 {
	color: var(--theme-color);
}

@media (--mq-from-medium) {
	.property-grid__scribble .shape-3 {
		width: 93px;
		height: 91px;
	}
}

@media (--mq-from-wide) {
	.property-grid__scribble .shape-3 {
		width: 131px;
		height: 129px;
	}
}

@media (--mq-up-to-small) {
	.property-grid__item {
		margin-bottom: var(--space-small);
	}
}

@media (--mq-from-small) {
	.property-grid__item {
		flex-basis: calc(50% - 0.75rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-wide) {
	.property-grid__item {
		flex-basis: calc(50% - (var(--space-xsmall) / 2));
	}
}

@media (--mq-up-to-small) {
	.property-grid__row:last-child .property-grid__item:last-child {
		margin-bottom: 0;
	}
}
