.arrow {
	background-color: var(--theme-color);
	border-radius: 50%;
	color: inherit;
	display: inline-block;
	position: relative;
	transition: color 96ms linear;
	width: 30px;
	height: 30px;
}

@media (--mq-from-large) {
	.arrow {
		width: 40px;
		height: 40px;
	}
}

.arrow:focus,
.arrow:hover {
	color: var(--color-white);
}

.arrow:focus {
	outline: 0;
}

.arrow:hover {
	cursor: pointer;
}

.arrow::after {
	border-color: transparent;
	border-style: solid;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transition: opacity 96ms linear;
	width: 0;
	height: 0;
}

.arrow::after {
	content: "";
}

.arrow:not(.arrow--left)::after {
	/* default arrow is right */
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 10px solid currentColor;
	transform: translate(-50%, -50%) translateX(3px);
}

.arrow.arrow--left::after {
	border-top: 6px solid transparent;
	border-right: 10px solid currentColor;
	border-bottom: 6px solid transparent;
	transform: translate(-50%, -50%) translateX(-3px);
}
