.pill-group {
	list-style: none;
	margin: 0;
	padding: 0;
}

.pill-group__item {
	display: inline-block;
}

.pill-group__item:not(:only-child) {
	margin-bottom: var(--space-xxsmall);
}

.pill-group__item:not(:last-child):not(:only-child) {
	margin-right: 0.4375rem;
}
