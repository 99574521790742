@media (--mq-from-small) {
	.property-area__detail {
		display: flex;
		justify-content: flex-start;
	}
}

@media (--mq-up-to-small) {
	.property-area__column {
		margin-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-small) {
	.property-area__column {
		flex-basis: calc(50% - var(--space-xsmall));
	}

	.property-area__column:last-child:not(:only-child) {
		margin-left: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.property-area__column {
		flex-basis: calc(50% - var(--space-small));
	}

	.property-area__column:last-child:not(:only-child) {
		margin-left: var(--space-medium);
	}
}

.property-area__title {
	color: var(--theme-color-dark);
}

@media (--mq-from-small) {
	.property-area__detail + .property-area__hotspots {
		padding-top: calc(var(--space-small) - 10px);
	}
}

@media (--mq-from-medium) {
	.property-area__detail + .property-area__hotspots {
		padding-top: calc(var(--space-medium) - 10px);
	}
}

@media (--mq-from-large) {
	.property-area__detail + .property-area__hotspots {
		padding-top: calc(var(--space-large) - 10px);
	}
}
