.hotspots__list {
	list-style: none;
	margin-bottom: 0;
	padding: 0;
}

@media (--mq-from-small) {
	.hotspots__list {
		column-count: 2;
		column-gap: var(--space-xsmall);
	}
}

@media (--mq-from-medium) {
	.hotspots__list {
		column-count: 3;
		column-gap: var(--space-small);
	}
}

.hotspots__title {
	color: var(--theme-color-dark);
	margin-bottom: var(--space-xxsmall);
}

@media (--mq-from-medium) {
	.hotspots__title {
		margin-bottom: var(--space-xsmall);
	}
}

.t-pink .hotspots__title {
	color: var(--theme-color);
}

.hotspots__list__item {
	margin-bottom: var(--space-xsmall);
}

.hotspots__list__item .icon {
	color: var(--theme-color-dark);
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
}

.t-pink .hotspots__list__item .icon {
	color: var(--theme-color);
}

.hotspots__list__text {
	color: var(--color-black);
	display: inline-block;
	vertical-align: middle;
}

.icon-star + .hotspots__list__text {
	margin-left: var(--space-xxsmall);
}

.hotspots__list__tag {
	color: var(--theme-color-dark) !important;
	display: inline-block;
	vertical-align: middle;
}

.t-pink .hotspots__list__tag {
	color: var(--theme-color) !important;
}

.hotspots__list__text + .hotspots__list__tag {
	margin-left: var(--space-xxsmall);
}
