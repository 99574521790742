.property-detail {
	padding-bottom: var(--space-small);
}

@media (min-width: 1024px) {
	.property-detail {
		display: flex;
		padding-bottom: var(--space-medium);
		justify-content: flex-end;
		align-items: flex-start;
	}
}

@media (min-width: 1380px) {
	.property-detail {
		padding-bottom: var(--space-large);
	}
}

@media (min-width: 1024px) {
	.property-detail__column {
		width: calc((100% - var(--space-medium)) / 2);
	}
}

@media (min-width: 1680px) {
	.property-detail__column {
		width: calc((100% - var(--space-large)) / 2);
	}
}

@media (min-width: 768px) {
	.property-detail__column.property-detail__column--small {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
}

@media (min-width: 1024px) {
	.property-detail__column:nth-child(even) {
		margin-left: var(--space-medium);
	}
}

@media (min-width: 1680px) {
	.property-detail__column:nth-child(even) {
		margin-left: var(--space-large);
	}
}

@media (max-width: 1023px) {
	.property-detail__column:not(:first-child):not(:only-child) {
		margin-top: var(--space-xsmall);
	}
}

@media (min-width: 768px) {
	.property-detail__column--small .property-detail__column__item {
		width: calc((100% - var(--space-small)) / 2);
	}
}

@media (min-width: 1024px) {
	.property-detail__column--small .property-detail__column__item {
		width: calc((100% - var(--space-medium)) / 2);
	}
}

@media (min-width: 1680px) {
	.property-detail__column--small .property-detail__column__item {
		width: calc((100% - var(--space-large)) / 2);
	}
}

@media (min-width: 768px) {
	.property-detail__column--small .property-detail__column__item:nth-child(even) {
		margin-left: var(--space-small);
	}
}

@media (min-width: 1024px) {
	.property-detail__column--small .property-detail__column__item:nth-child(even) {
		margin-left: var(--space-medium);
	}
}

@media (min-width: 1680px) {
	.property-detail__column--small .property-detail__column__item:nth-child(even) {
		margin-left: var(--space-large);
	}
}

@media (max-width: 767px) {
	.property-detail__column__item:not(:first-child):not(:only-child) {
		margin-top: var(--space-xsmall);
	}
}

@media (min-width: 768px) {
	.property-detail__column--left .property-detail__column__item:nth-child(1n + 3) {
		margin-top: var(--space-small);
	}
}

@media (min-width: 768px) {
	.property-detail__column--right .property-detail__column__item:not(:first-child):not(:only-child) {
		margin-top: var(--space-small);
	}
}
