.hero {
	background-color: var(--color-grey);
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.hero__rail {
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	align-items: stretch;
	position: relative;
	width: 100%;
}

.hero__item {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0;
	opacity: 0;
	overflow: hidden;
	transition: opacity 248ms linear;
	width: 100%;
	z-index: 0;
}

.hero__item.is-active {
	opacity: 1;
	order: -1;
	transition: opacity 124ms 124ms linear;
}

.hero__navigation {
	display: flex;
	opacity: 0;
	position: absolute;
	bottom: var(--space-small);
	left: 50%;
	transform: translate(-50%, 0);
	transition: opacity 96ms linear;
	z-index: 10;
}

@media (--mq-from-medium) {
	.hero__navigation {
		bottom: var(--space-medium);
	}
}

@media (--mq-from-wide) {
	.hero__navigation {
		bottom: var(--space-large);
	}
}

@media (--mq-from-large) {
	.hero__navigation {
		bottom: var(--space-xlarge);
	}
}

.hero.is-resolved .hero__navigation {
	opacity: 1;
}

.hero__button {
	appearance: none;
	background: none;
	background-color: var(--color-white);
	border: 0;
	border-radius: 50%;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	margin-right: 5px;
	margin-left: 5px;
	opacity: 0.5;
	padding: 0;
	position: relative;
	transition: opacity 240ms linear;
	width: 10px;
	height: 10px;
}

.hero__button:focus,
.hero__button:hover {
	opacity: 0.8;
	outline: 0;
}

.hero__button:hover {
	cursor: pointer;
}

.hero__button.is-active {
	opacity: 1;
}
