/*
 * This layouts contains the (1) main content and the (2) site footer.
 * It make sure the footer always sticks to the bottom.
 */

.l-site-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-height: 100vh;
}

/* Only if
	 * - there's a site-header on the same level
	 * - if the view doesn't start with a hero
	 */

body:not(.u-has-hero) .site-header ~ .l-site-container {
	box-sizing: border-box;
	padding-top: calc(var(--site-header-height-small) + var(--space-small));
}

@media (--mq-from-medium) {
	body:not(.u-has-hero) .site-header ~ .l-site-container {
		padding-top: var(--space-medium);
	}
}

@media (--mq-from-large) {
	body:not(.u-has-hero) .site-header ~ .l-site-container {
		padding-top: var(--space-xlarge);
	}
}

@media (--mq-up-to-small) {
	body.u-has-mobile-hero .site-header ~ .l-site-container {
		padding-top: 0;
	}
}

body.u-hide-container-padding .site-header ~ .l-site-container {
	padding-top: var(--site-header-height-small);
}

@media (--mq-from-medium) {
	body.u-hide-container-padding .site-header ~ .l-site-container {
		padding-top: 0;
	}
}

@media (--mq-from-large) {
	body.u-hide-container-padding .site-header ~ .l-site-container {
		padding-top: 0;
	}
}

/* (1) */
.l-site-container__content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	width: 100%;
}

/* (2) */
.l-site-container__footer {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;
}
