.property-header {
	background-color: var(--color-white);
	box-sizing: border-box;
	color: var(--theme-color);
	display: flex;
	padding-top: var(--space-xxsmall);
	padding-bottom: var(--space-xxsmall);
	align-items: center;
	position: sticky;
	top: var(--site-header-height-small);
	min-height: var(--property-header-min-height-small);
	z-index: 97;
}

@media (--mq-from-xsmall) {
	.property-header {
		padding-top: var(--space-xsmall);
		padding-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-small) {
	.property-header {
		top: 0;
		min-height: var(--property-header-min-height-large);
	}
}

.l-styleguide .property-header {
	position: relative;
	top: auto;
}

@media (--mq-from-xsmall) {
	.property-header__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.property-header__title {
	margin-bottom: 0;
}

@media (--mq-up-to-xsmall) {
	.property-header__title {
		display: none;
	}
}

@media (--mq-from-xsmall) {
	.property-header__title {
		flex-basis: 396px;
		flex-grow: 0;
		flex-shrink: 1;
	}
}

@media (--mq-from-small) {
	.property-header__title {
		flex-basis: 50%;
		flex-shrink: 0;
	}
}

.property-header__title__link {
	color: var(--theme-color);
	text-decoration: none;
}

.property-header__title__link:focus {
	outline: 0;
}

.property-header__title__link:hover,
.property-header__title__link:focus {
	text-decoration: underline;
}

@media (--mq-from-xsmall) {
	.property-header__navigation {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 1;
	}
}

.property-header__navigation-list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: space-between;
	align-items: center;
}

@media (--mq-from-xsmall) {
	.property-header__navigation-list {
		justify-content: flex-end;
	}
}

@media (--mq-from-xsmall) {
	.property-header__navigation-list__item {
		margin-left: var(--space-xsmall);
	}
}

@media (--mq-from-medium) {
	.property-header__navigation-list__item {
		margin-left: 1.875rem;
	}
}

@media (--mq-from-large) {
	.property-header__navigation-list__item {
		margin-left: var(--space-small);
	}
}

.property-header__navigation-list__link {
	color: currentColor;
	font-size: 1rem;
	line-height: 1.31;
	text-decoration-color: var(--color-transparent-green);
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-transform: uppercase;
	transition: text-decoration-color 96ms linear;
}

.property-header__navigation-list__item:not(.property-header__navigation-list__link--call-to-action) .property-header__navigation-list__link:focus,
.property-header__navigation-list__item:not(.property-header__navigation-list__link--call-to-action) .property-header__navigation-list__link:hover,
.property-header__navigation-list__item:not(.property-header__navigation-list__link--call-to-action) .property-header__navigation-list__link.is-active {
	text-decoration-color: var(--theme-color);
}

.property-header__navigation-list__link:focus {
	outline: 0;
}
