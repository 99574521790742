.inspiration-row {
	margin-top: var(--space-small);
}

@media (--mq-from-medium) {
	.inspiration-row {
		margin-top: var(--space-medium);
	}
}

@media (--mq-from-large) {
	.inspiration-row {
		margin-top: var(--space-large);
	}
}

.inspiration-row:last-child {
	margin-bottom: var(--space-small);
}

@media (--mq-from-medium) {
	.inspiration-row:last-child {
		margin-bottom: var(--space-medium);
	}
}

@media (--mq-from-large) {
	.inspiration-row:last-child {
		margin-bottom: var(--space-large);
	}
}
