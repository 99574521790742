.property-teaser {
	position: relative;
	width: 100%;
}

/*
	 * This code takes care of the virtual offset of the grid items in the
	 * default (so, non-featured) property-grid:
	 *
	 *       ___
	 *  ___ | 2 |
	 * | 1 | ---
	 *  ---
	 *       ___
	 *  ___ | 4 |
	 * | 3 | ---
	 *  ---
	 *     _____
	 *    | CTA |
	 *     -----
	 *  ___
	 * | 5 | ___
	 *  --- | 6 |
	 *       ---
	 *
	 * The offset is applied to the first child of the row, and the last child
	 * on the rows after the CTA.
	 *
	 * Offset isn't needed for items in the featured grid, or items that are
	 * the only child in a row
	 *
	 * Offset calculation is: (1/2 item height) - (approx. title/terms height)
	 *
	 * Title and terms can vary in height based on length, so the calc might
	 * be off a bit. We're okay with that.
	 *
	 */
@media (--mq-from-small) {
	.property-grid:not(.property-grid--featured) .property-grid__row .property-grid__item:first-child:not(:only-child) .property-teaser,
	.property-grid:not(.property-grid--featured) .property-grid__row--call-to-action ~ .property-grid__row .property-grid__item:last-child:not(:only-child) .property-teaser {
		padding-top: calc(50% - 3.5rem);
	}
}

@media (--mq-from-medium) {
	.property-grid:not(.property-grid--featured) .property-grid__row .property-grid__item:first-child:not(:only-child) .property-teaser,
	.property-grid:not(.property-grid--featured) .property-grid__row--call-to-action ~ .property-grid__row .property-grid__item:last-child:not(:only-child) .property-teaser {
		padding-top: calc(50% - 4.75rem);
	}
}

@media (--mq-from-wide) {
	.property-grid:not(.property-grid--featured) .property-grid__row .property-grid__item:first-child:not(:only-child) .property-teaser,
	.property-grid:not(.property-grid--featured) .property-grid__row--call-to-action ~ .property-grid__row .property-grid__item:last-child:not(:only-child) .property-teaser {
		padding-top: calc(50% - 6rem);
	}
}

@media (--mq-from-large) {
	.property-grid:not(.property-grid--featured) .property-grid__row .property-grid__item:first-child:not(:only-child) .property-teaser,
	.property-grid:not(.property-grid--featured) .property-grid__row--call-to-action ~ .property-grid__row .property-grid__item:last-child:not(:only-child) .property-teaser {
		padding-top: calc(50% - 6.875rem);
	}
}

@media (--mq-from-xlarge) {
	.property-grid:not(.property-grid--featured) .property-grid__row .property-grid__item:first-child:not(:only-child) .property-teaser,
	.property-grid:not(.property-grid--featured) .property-grid__row--call-to-action ~ .property-grid__row .property-grid__item:last-child:not(:only-child) .property-teaser {
		padding-top: calc(50% - 8.125rem);
	}
}

@media (--mq-from-small) {
	.property-grid:not(.property-grid--featured) .property-grid__row--call-to-action ~ .property-grid__row .property-grid__item:first-child .property-teaser {
		padding-top: 0;
	}
}

.property-teaser__link {
	color: var(--theme-color);
	display: block;
	text-align: center;
	text-decoration: none;
	transition: color 96ms linear;
	width: 100%;
}

.property-teaser__link:focus,
.property-teaser__link:hover {
	color: var(--theme-color-dark);
}

.property-teaser__link:focus {
	outline: 0;
}

@media (--mq-up-to-small) {
	.property-teaser__meta,
	.property-teaser__title {
		box-sizing: border-box;
		display: inline-block;
		padding-right: var(--space-xsmall);
		padding-left: var(--space-xsmall);
		width: 100%;
	}
}

@media (--mq-from-small) {
	.property-teaser__title {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		box-sizing: border-box;
		display: -webkit-box;
		overflow: hidden;
		padding-right: var(--space-xsmall);
		padding-left: var(--space-xsmall);
		justify-content: center;
		align-items: center;
		height: 50px;
		/* stylelint-disable-next-line declaration-property-value-no-unknown */
		text-overflow: -o-ellipsis-lastline;
		width: 100%;
	}
}

@media (--mq-from-medium) {
	.property-teaser__title {
		height: 58px;
	}
}

@media (--mq-from-large) {
	.property-teaser__title {
		height: 66px;
	}
}

.property-teaser__link:not(:last-child) .property-teaser__title:not(:last-child) {
	margin-bottom: var(--space-xxsmall);
}

@media (--mq-from-wide) {
	.property-teaser__link:not(:last-child) .property-teaser__title:not(:last-child) {
		margin-bottom: var(--space-xsmall);
	}
}

.property-teaser__thumbnail-wrapper {
	display: block;
	overflow: hidden;
	padding-bottom: 68.115942%; /* 60:47 */
	position: relative;
	transition: background-color 96ms linear;
	width: 100%;
}

.property-teaser__link:focus .property-teaser__thumbnail-wrapper,
.property-teaser__link:hover .property-teaser__thumbnail-wrapper {
	background-color: var(--theme-color);
}

.property-teaser__link:not(:last-child) .property-teaser__thumbnail-wrapper:not(:last-child) {
	margin-bottom: var(--space-xxsmall);
}

.property-teaser__thumbnail {
	backface-visibility: hidden;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0) scale(1, 1);
	transform-origin: center center;
	transition: transform 320ms ease-out;
	width: 100%;
	z-index: 1;
}

.property-teaser__link:focus .property-teaser__thumbnail,
.property-teaser__link:hover .property-teaser__thumbnail {
	transform: translate3d(-50%, -50%, 0) scale(1.04, 1.04);
	transition: transform 1120ms cubic-bezier(0.25, 0, 0.15, 1);
}

.property-teaser__terms {
	display: flex;
	list-style: none;
	margin: var(--space-xsmall) 0 0;
	padding: 0;
	align-items: center;
}

@media (--mq-up-to-small) {
	.property-teaser__terms {
		flex-wrap: nowrap;
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
	}
}

@media (--mq-from-small) {
	.property-teaser__terms {
		flex-wrap: wrap;
		margin-bottom: -0.635rem;
		justify-content: center;
	}
}

@media (--mq-up-to-small) {
	.property-teaser__terms__item {
		flex-shrink: 0;
		padding-left: var(--space-xxsmall);
		scroll-snap-align: start;
	}
}

@media (--mq-from-small) {
	.property-teaser__terms__item {
		margin: 0 0.3125rem 0.635rem 0.3125rem;
	}
}

@media (--mq-up-to-small) {
	.property-teaser__terms__item:first-child {
		padding-left: var(--space-xsmall);
	}
}

@media (--mq-up-to-small) {
	.property-teaser__terms__item:last-child {
		padding-right: var(--space-xsmall);
	}
}
