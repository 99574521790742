.site-footer {
	background-color: var(--color-blue);
	box-sizing: border-box;
	color: var(--color-white);
	font-size: 1rem;
	line-height: normal;
	padding: var(--space-small) var(--space-xsmall);
	width: 100%;
}

@media (--mq-from-medium) {
	.site-footer {
		padding-top: var(--space-xsmall);
		padding-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-large) {
	.site-footer {
		padding: var(--space-large) 0;
	}
}

@media (--mq-from-large) {
	.site-footer__wrapper {
		margin-right: auto;
		margin-left: auto;
		width: 960px;
	}
}

.site-footer__wrapper:not(:last-child):not(:only-child) {
	margin-bottom: var(--space-small);
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-footer__wrapper:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-medium);
	}
}

@media (--mq-from-large) {
	.site-footer__wrapper:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-small) {
	.site-footer__content {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (--mq-from-small) {
	.site-footer__content__column {
		flex-basis: 220px;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-large) {
	.site-footer__content__column {
		flex-basis: 250px;
	}
}

@media (--mq-up-to-small) {
	.site-footer__content__column:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-small);
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-footer__content__column:nth-child(2) {
		display: none;
	}
}

@media (--mq-from-medium) {
	.site-footer__content__column:nth-child(2) {
		text-align: center;
	}
}

@media (--mq-from-small) {
	.site-footer__content__column:last-child {
		text-align: right;
	}
}

.site-footer__content__column a:not([class]) {
	color: currentColor;
}

.site-footer__content__column a:not([class]):focus,
.site-footer__content__column a:not([class]):hover {
	color: var(--color-darkened-green);
}

.site-footer__content__title {
	margin-bottom: var(--space-xsmall);
	text-transform: uppercase;
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-footer__content__title {
		margin-bottom: var(--space-xxsmall);
	}
}

.site-footer__social-links {
	padding-top: 0.375rem;
}

@media (--mq-from-medium) {
	.site-footer__social-links {
		padding-top: 1.75rem;
	}
}

.site-footer__tablet-newsletter-content {
	display: none;
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-footer__tablet-newsletter-content {
		display: block;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-footer__tablet-newsletter-content:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-small);
	}
}

.site-footer__navigation-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media (--mq-from-medium) {
	.site-footer__navigation-container {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.site-footer__logo {
	color: currentColor;
	margin-bottom: var(--space-xxsmall);
}

@media (--mq-from-medium) {
	.site-footer__logo {
		margin-right: var(--space-small);
	}
}

@media (--mq-from-large) {
	.site-footer__logo {
		margin-right: 9.375rem;
	}
}

.site-footer__logo:focus,
.site-footer__logo:hover {
	color: var(--color-darkened-green);
}

.site-footer__baseline {
	font-size: 1.125rem;
	line-height: normal;
}

@media (--mq-from-medium) {
	.site-footer__baseline {
		font-size: 1.375rem;
	}
}

.site-footer__navigation {
	margin-top: var(--space-small);
}

@media (--mq-from-medium) {
	.site-footer__navigation {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}
