.media-block {
	display: block;
	position: relative;
	width: 100%;
}

.media-block[data-aspect-ratio] {
	overflow: hidden;
	padding-top: 100%;
}

.media-block[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

.media-block[data-aspect-ratio="1:2"] {
	padding-top: 200%;
}

.media-block[data-aspect-ratio="2:1"] {
	padding-top: 50%;
}

.media-block[data-aspect-ratio="2:3"] {
	padding-top: calc(100% * (3 / 2));
}

.media-block[data-aspect-ratio="3:2"] {
	padding-top: calc(100% * (2 / 3));
}

.media-block[data-aspect-ratio="3:4"] {
	padding-top: calc(100% * (4 / 3));
}

.media-block[data-aspect-ratio="4:3"] {
	padding-top: calc(100% * (3 / 4));
}

.media-block[data-aspect-ratio="16:9"] {
	padding-top: calc(100% * (9 / 16));
}

.media-block[data-aspect-ratio="21:9"] {
	padding-top: calc(100% * (9 / 21));
}

.media-block__item {
	width: 100%;
	height: auto;
}

[data-aspect-ratio] .media-block__item {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	z-index: 0;
}

@supports (object-fit: cover) {
	[data-aspect-ratio] .media-block__item {
		object-fit: cover;
		max-width: none;
		height: 100%;
	}
}

.media-block__caption {
	box-sizing: border-box;
	color: var(--color-white);
	font-size: 0.75rem;
	padding: var(--space-xxsmall);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

@media (--mq-from-small) {
	.media-block__caption {
		font-size: 0.875rem;
	}
}

@media (--mq-from-wide) {
	.media-block__caption {
		font-size: 1rem;
		padding: var(--space-xsmall);
	}
}

.gallery .media-block__caption {
	text-align: right;
}
