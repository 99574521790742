.property-intro {
	line-height: 1.31;
	margin-top: var(--space-xxsmall);
}

@media (--mq-from-small) {
	.property-intro {
		margin-top: var(--space-xsmall);
		width: 450px;
	}
}

@media (--mq-from-wide) {
	.property-intro {
		line-height: 1.44;
		width: 500px;
	}
}
