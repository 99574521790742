.pill {
	appearance: none;
	background: none;
	background-color: var(--theme-color);
	border: 0;
	border-radius: var(--space-xsmall);
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-white);
	display: inline-block;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.0625rem;
	line-height: 1;
	padding: var(--space-xxsmall) var(--space-xsmall);
	text-align: center;
	text-decoration: none;
	transition:
		background-color 96ms linear,
		color 96ms linear;
}

@media (--mq-from-medium) {
	.pill {
		border-radius: 1.5rem;
		font-size: 1.125rem;
		line-height: 1.1666;
		padding-top: 0.9375rem;
		padding-bottom: 0.9375rem;
	}
}

.pill:focus,
.pill:hover {
	background-color: var(--theme-color-dark);
	color: var(--color-white);
}

.pill:focus {
	outline: 0;
}

.pill:hover {
	cursor: pointer;
}

/* LARGE */
@media (--mq-from-medium) {
	.pill.pill--large {
		border-radius: 2rem;
		font-size: 1.5rem;
		padding: var(--space-xsmall) var(--space-small);
	}
}

/* LIGHT COLORS */

.pill.pill--light {
	background-color: var(--color-white);
	color: var(--theme-color);
}

.pill.pill--light:focus,
.pill.pill--light:hover {
	background-color: var(--theme-color-dark);
	color: var(--color-white);
}

/* TIGHT ON MOBILE */
@media (--mq-up-to-xsmall) {
	.pill.pill--tight-on-mobile {
		padding-right: 0.4375rem;
		padding-left: 0.4375rem;
	}
}

.property-header__navigation .pill {
	width: auto;
	width: max-content;
}

.pill__label {
	display: inline-block;
	transform: translateY(-1px);
	width: 100%;
}

@media (--mq-from-medium) {
	.pill__label {
		transform: translateY(-2px);
	}
}

@media (--mq-from-medium) {
	.pill--large .pill__label {
		transform: translateY(-3px);
	}
}
