.site-header {
	width: 100%;
}

@media (--mq-up-to-small) {
	.site-header {
		position: fixed;
		top: 0;
		transition: background-color 96ms linear;
		z-index: 99;
	}
}

.l-styleguide .site-header {
	position: relative;
}

@media (--mq-up-to-small) {
	#mobile-navigation-toggle:checked ~ .site-header {
		background-color: var(--color-white);
	}
}

@media (--mq-up-to-small) {
	body.is-scrolled .site-header {
		background-color: var(--color-white);
	}
}

@media (--mq-from-small) {
	body.u-has-hero .site-header {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
	}
}

.site-header__wrapper {
	box-sizing: border-box;
	display: flex;
	padding-right: var(--space-xsmall);
	padding-left: var(--space-xsmall);
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: var(--site-header-height-small);
}

@media (--mq-from-small) {
	.site-header__wrapper {
		padding-top: var(--space-small);
		padding-right: 6%;
		padding-left: 12%;
		height: var(--site-header-height-medium);
	}
}

@media (--mq-from-medium) {
	.site-header__wrapper {
		padding-left: 20%;
	}
}

@media (--mq-from-large) {
	.site-header__wrapper {
		padding-top: var(--space-xlarge);
		padding-right: 11.5%;
		padding-left: 21.5%;
		height: var(--site-header-height-large);
	}
}

@media (min-width: 1440px) {
	.site-header__wrapper {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 960px;
	}
}

.site-header__logo-container {
	transform: translateY(4px);
}

@media (min-width: 1440px) {
	.site-header__logo-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 260px;
	}
}

.site-header__logo {
	color: currentColor;
	text-decoration: none;
	transition: color;
}

.site-header__logo:focus,
.site-header__logo:hover {
	color: var(--color-darkened-green);
}

@media (--mq-up-to-small) {
	.site-header__navigation-container {
		display: none;
	}
}

@media (min-width: 1440px) {
	.site-header__navigation-container {
		display: flex;
		flex-direction: column;
		overflow: visible;
		justify-content: center;
		align-items: center;
		white-space: nowrap;
		width: 260px;
	}
}

@media (min-width: 1440px) {
	.site-header__navigation {
		width: auto;
		min-width: 100%;
	}
}

.site-header__navigation:not(:last-child):not(:only-child) {
	margin-bottom: var(--space-xsmall);
}

@media (--mq-from-small) {
	.site-header__navigation:not(:last-child):not(:only-child) {
		margin-bottom: 2.875rem;
	}
}

@media (--mq-from-small) {
	.site-header__mobile-navigation-toggle-container {
		display: none;
	}
}

.site-header__mobile-navigation-toggle {
	display: block;
	position: relative;
	width: 1.875rem;
	height: 1.875rem;
}

.site-header__mobile-navigation-toggle__close,
.site-header__mobile-navigation-toggle__close > svg,
.site-header__mobile-navigation-toggle__hamburger,
.site-header__mobile-navigation-toggle__hamburger > svg {
	color: currentColor;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#mobile-navigation-toggle:not(:checked) ~ .site-header .site-header__mobile-navigation-toggle__close {
	display: none;
}

#mobile-navigation-toggle:checked ~ .site-header .site-header__mobile-navigation-toggle__hamburger {
	display: none;
}
