@font-face {
	font-family: "National Regular";
	src: url("../fonts/NationalWeb-Regular.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "National Regular";
	src: url("../fonts/NationalWeb-RegularItalic.woff2") format("woff2");
	font-style: italic;
	font-weight: 400;
}
