.accordion {
	border-right: 1px solid var(--theme-color);
	border-left: 1px solid var(--theme-color);
	position: relative;
	width: 100%;
}

.accordion__item {
	border-top: 1px solid var(--theme-color);
	width: 100%;
}

.accordion__item:last-child {
	border-bottom: 1px solid var(--theme-color);
}

.accordion__checkbox {
	display: none;
}

.accordion__title {
	color: var(--theme-color);
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	position: relative;
	user-select: none;
	width: 100%;
}

.accordion__title::after {
	color: currentColor;
	content: "+";
	display: block;
	font-size: 1.5rem;
	line-height: 1;
	position: absolute;
	top: 0.3125rem;
	right: var(--space-xsmall);
	transform-origin: center;
	transition: color 96ms linear;
	z-index: 1;
}

.accordion__title:focus::after,
.accordion__title:hover::after,
.accordion__checkbox:checked ~ .accordion__title::after {
	color: var(--color-blue);
}

.accordion__checkbox:checked ~ .accordion__title::after {
	content: "×";
	right: calc(var(--space-xsmall) + 0.03125rem);
}

.accordion__title__toggle {
	box-sizing: border-box;
	display: block;
	padding: var(--space-xxsmall) var(--space-medium) var(--space-xxsmall) var(--space-xsmall);
	position: relative;
	width: 100%;
	z-index: 2;
}

.accordion__title__toggle:focus {
	outline: 0;
}

.accordion__title__toggle:hover {
	cursor: pointer;
}

.accordion__content {
	box-sizing: border-box;
	padding-right: var(--space-xsmall);
	padding-bottom: var(--space-xxsmall);
	padding-left: var(--space-xsmall);
	width: 100%;
}

.accordion__checkbox:not(:checked) ~ .accordion__content {
	display: none;
}
