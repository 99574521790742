.pagination {
	color: var(--color-blue);
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 4.6875rem 0;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (--mq-from-xlarge) {
	.pagination {
		padding-top: 8.125rem;
		padding-bottom: 8.125rem;
	}
}

.pagination__link-container {
	flex-basis: 30px;
	flex-grow: 0;
	flex-shrink: 0;
	height: 30px;
}

@media (--mq-from-large) {
	.pagination__link-container {
		flex-basis: 40px;
		height: 40px;
	}
}

.pagination__link:not(.pagination__link--active) {
	opacity: 0.5;
	pointer-events: none;
}

.pagination__link:not(.pagination__link--active):hover {
	cursor: default;
}

.pagination__pages-container {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
	margin-right: var(--space-xsmall);
	margin-left: var(--space-xsmall);
	text-align: center;
	max-width: calc(100% - 7.75rem);
}

@media (--mq-from-medium) {
	.pagination__pages-container {
		margin-right: var(--space-small);
		margin-left: var(--space-small);
	}
}

@media (--mq-from-xlarge) {
	.pagination__pages-container {
		max-width: calc(100% - 9rem);
	}
}

.pagination__pages {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: stretch;
	transform: translateY(-1px);
	width: 100%;
}

@media (--mq-from-medium) {
	.pagination__pages {
		transform: translateY(-2px);
	}
}

@media (--mq-from-xlarge) {
	.pagination__pages {
		transform: translateY(-3px);
	}
}

.pagination__pages__item {
	margin: 0.5rem;
}

@media (--mq-from-small) {
	.pagination__pages__item {
		margin-top: 0;
		margin-bottom: 0;
	}
}

@media (--mq-from-medium) {
	.pagination__pages__item {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
}

@media (--mq-from-medium) {
	.pagination__pages__item:first-child {
		margin-left: 0;
	}
}

@media (--mq-from-medium) {
	.pagination__pages__item:last-child {
		margin-right: 0;
	}
}

@media (--mq-from-medium) {
	.pagination__pages__item:only-child {
		margin-right: 0;
		margin-left: 0;
	}
}

.pagination__pages__current-page-label,
.pagination__pages__item {
	color: inherit;
	display: inline-block;
}

.pagination__pages__current-page-label,
.pagination__pages__link {
	line-height: var(--space-xsmall);
}

.pagination__pages__link {
	color: currentColor;
	text-decoration: none;
	transition: opacity 96ms linear;
}

.pagination__pages__link:focus,
.pagination__pages__link:hover {
	opacity: 0.5;
}

.pagination__pages__current-page-label {
	border-bottom: 1px solid currentColor;
}

.pagination__pages__current-page-label:hover {
	cursor: default;
}
