html {
	scroll-behavior: smooth;
}

body {
	background-color: var(--color-white);
	color: var(--color-black);
	margin: 0;
	padding: 0;
}

textarea {
	resize: none;
}

hr {
	background-color: var(--theme-color);
	border: none;
	height: 1px;
}

img,
video {
	display: block;
	max-width: 100%;
}

table {
	border: 1px solid var(--theme-color);
	border-collapse: collapse;
	margin: var(--space-xsmall) 0;
	padding-bottom: var(--space-xxsmall);
	width: 100%;
	max-width: 100%;
}

@media (--mq-up-to-small) {
	table {
		display: block;
		overflow-y: auto;
		width: 100%;
	}
}

@media (--mq-from-medium) {
	table {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	box-sizing: border-box;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.31;
	text-align: left;
}

@media (--mq-from-large) {
	th,
	td {
		font-size: 1.125rem;
		line-height: normal;
	}
}

th:not(:last-child),
td:not(:last-child) {
	border-right: 1px solid var(--theme-color);
}

td {
	padding: var(--space-xxsmall) var(--space-xsmall);
}

tbody tr:first-child td {
	padding-top: var(--space-xsmall);
}

tbody:last-child tr:last-child td,
tfoot:last-child tr:last-child td,
thead:last-child tr:last-child td {
	padding-bottom: var(--space-xsmall);
}

th {
	border-bottom: 1px solid var(--theme-color);
	color: var(--theme-color);
	padding: var(--space-xsmall);
	text-transform: uppercase;
}

ol:not([class]),
ul:not([class]) {
	box-sizing: border-box;
	line-height: 1.5;
	list-style: none;
	margin: 0 0 0.9375rem 0;
}

@media (--mq-from-medium) {
	ol:not([class]),
	ul:not([class]) {
		margin-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-large) {
	ol:not([class]),
	ul:not([class]) {
		line-height: 1.67;
	}
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ol:not([class]) {
	counter-reset: ol-list-items;
	padding-left: 2.3125rem;
}

ol:not([class]) ol:not([class]) {
	counter-reset: ol-sub-list-items;
	padding-left: 1.625rem;
}

ul:not([class]) {
	padding-left: var(--space-xsmall);
}

ul:not([class]) ul:not([class]) {
	padding-left: 1rem;
}

li:not([class]) {
	position: relative;
}

ul:not([class]) li:not([class])::before {
	content: "– ";
}

ol:not([class]) li:not([class])::before {
	color: black;
	content: counter(ol-list-items) ".";
	counter-increment: ol-list-items;
	position: absolute;
	top: 0;
	left: -1rem;
}

ol:not([class]) ol:not([class]) li:not([class])::before {
	content: counter(ol-list-items) "." counter(ol-sub-list-items) ".";
	counter-increment: ol-sub-list-items;
	position: absolute;
	left: -1.625rem;
}

@media (--mq-up-to-xsmall) {
	.g-recaptcha {
		transform: scale(0.85);
		transform-origin: 0 0;
	}
}
