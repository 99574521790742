.property-section {
	margin-top: calc((var(--site-header-height-small) + var(--property-header-min-height-small)) * -1);
	padding-top: calc(var(--site-header-height-small) + var(--property-header-min-height-small));
}

@media (--mq-from-medium) {
	.property-section {
		margin-top: calc(var(--property-header-min-height-large) * -1);
		padding-top: var(--property-header-min-height-large);
	}
}
