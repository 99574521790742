.property-row {
	margin-top: var(--space-small);
	margin-bottom: var(--space-small);
	position: relative;
}

@media (--mq-from-medium) {
	.property-row {
		display: flex;
		margin-top: var(--space-medium);
		margin-bottom: var(--space-medium);
		align-items: flex-start;
	}
}

@media (--mq-from-wide) {
	.property-row {
		margin-top: var(--space-large);
		margin-bottom: var(--space-large);
	}
}

@media (--mq-from-large) {
	.property-row {
		box-sizing: border-box;
		margin-right: auto;
		margin-left: auto;
		max-width: 1440px;
	}
}

@media (--mq-from-medium) {
	.property-row.property-row--align-left {
		justify-content: flex-start;
	}
}

@media (--mq-from-medium) {
	.property-row.property-row--align-center {
		justify-content: center;
	}
}

@media (--mq-from-medium) {
	.property-row.property-row--align-right {
		justify-content: flex-end;
	}
}

@media (--mq-from-small) {
	.property-row__wrapper {
		padding-right: var(--space-xsmall);
		padding-left: var(--space-xsmall);
	}
}

@media (--mq-from-medium) {
	.property-row__wrapper {
		padding-right: var(--space-small);
		padding-left: var(--space-small);
		width: 70%;
	}
}

@media (--mq-from-wide) {
	.property-row__wrapper {
		width: 75%;
	}
}

.property-row__scribble {
	position: absolute;
	top: 50%;
	width: 169px;
	height: 130px;
}

@media (--mq-up-to-medium) {
	.property-row__scribble {
		display: none;
	}
}

@media (--mq-up-to-wide) {
	.property-row__scribble {
		transform: translateY(-100%);
	}
}

@media (--mq-from-wide) {
	.property-row__scribble {
		width: 190px;
		height: 146px;
	}
}

.property-row--align-left .property-row__scribble {
	right: var(--space-small);
}

.property-row--align-right .property-row__scribble {
	left: var(--space-small);
}

.property-row__scribble .shape-2 {
	color: var(--theme-color);
	width: 100%;
	height: 100%;
}
