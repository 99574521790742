.form {
	margin-top: var(--space-xsmall);
	margin-bottom: var(--space-xsmall);
	position: relative;
	width: 100%;
	max-width: 560px;
}

@media (--mq-from-medium) {
	.form {
		margin-top: var(--space-small);
	}
}

.form__row {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
}

.form__row:not(:last-child) {
	margin-bottom: var(--space-xsmall);
}

.form__column {
	display: flex;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: space-between;
	align-items: flex-start;
}

@media (--mq-up-to-wide) {
	.form__column:not(:only-child):not(:last-child) {
		margin-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-wide) {
	.form__column:not(:only-child) {
		flex-basis: calc(50% - (var(--space-xsmall) / 2));
	}
}

.form__label-container {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
}

@media (min-width: 375px) and (--mq-up-to-medium) {
	.form__label-container {
		flex-basis: 90px;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.form__field-container {
	flex-basis: calc(67.85% + var(--space-xxsmall));
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: var(--space-xxsmall);
}

@media (min-width: 375px) and (--mq-up-to-medium) {
	.form__field-container {
		flex-basis: calc(100% - 90px);
		margin-left: 0;
	}
}

@media (--mq-from-medium) {
	.form__field-container {
		flex-basis: calc(80% + var(--space-xxsmall));
	}
}

@media (--mq-from-wide) {
	.form__field-container {
		flex-basis: calc(82.2% + var(--space-xxsmall));
	}
}

@media (--mq-from-wide) {
	.form__column:not(:only-child) .form__field-container {
		flex-basis: calc(63% + var(--space-xxsmall));
	}
}

.form__label {
	color: var(--theme-color);
	line-height: 40px;
}

.form__field {
	background: none;
	border: 1px solid var(--theme-color);
	border-radius: 3px;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-black);
	display: block;
	line-height: 40px;
	padding: 0 0.5rem;
	width: 100%;
	height: 40px;
}

@media (--mq-from-medium) {
	.form__field {
		padding-right: 0.8125rem;
		padding-left: 0.8125rem;
	}
}

.form__field:focus {
	border-color: var(--theme-color-dark);
	outline: 0;
}

textarea.form__field {
	line-height: 1.4444;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	height: 100px;
}

@media (--mq-from-medium) {
	textarea.form__field {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		height: 88px;
	}
}

@media (--mq-from-wide) {
	textarea.form__field {
		height: 90px;
	}
}

.form__field.has-error {
	background-image: url("../images/error.png");
	background-position: calc(100% - 0.5rem) 50%;
	background-repeat: no-repeat;
	background-size: 1rem 1rem;
	padding-right: 2rem;
}

@media (--mq-from-medium) {
	.form__field.has-error {
		background-position: calc(100% - 0.8125rem) 50%;
		padding-right: 2.5rem;
	}
}

textarea.form__field.has-error {
	background-position: calc(100% - 0.5rem) 0.5rem;
}

@media (--mq-from-medium) {
	textarea.form__field.has-error {
		background-position: calc(100% - 0.8125rem) 0.8125rem;
	}
}

.form__submit-container {
	box-sizing: border-box;
	width: 100%;
}

@media (max-width: 374px) {
	.form__submit-container {
		padding-left: calc(100% - (67.85% + var(--space-xxsmall)));
	}
}

@media (min-width: 375px) and (--mq-up-to-medium) {
	.form__submit-container {
		padding-left: 90px;
	}
}

@media (--mq-from-medium) {
	.form__submit-container {
		display: flex;
		justify-content: center;
	}
}
