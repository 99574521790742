.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.u-text-light {
	color: var(--color-white);
}
