.meta-menu {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
}

.meta-menu__item {
	color: currentColor;
	text-align: center;
}

.meta-menu__item:not(:first-child):not(:only-child) {
	margin-left: 0.8125rem;
}

@media (--mq-from-large) {
	.meta-menu__item:not(:first-child):not(:only-child) {
		margin-left: 0.5625rem;
	}
}

.meta-menu__item:not(:last-child):not(:only-child) {
	margin-right: 0.8125rem;
}

@media (--mq-from-large) {
	.meta-menu__item:not(:last-child):not(:only-child) {
		margin-right: 0.5625rem;
	}
}

.meta-menu__link {
	color: currentColor;
	font-size: 0.875rem;
	line-height: normal;
	opacity: 0.39;
	text-decoration: underline;
	transition: opacity 96ms linear;
}

.meta-menu__link:focus,
.meta-menu__link:hover,
.current-menu-item .meta-menu__link {
	opacity: 1;
}
