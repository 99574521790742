.property-hero {
	position: relative;
	width: 100%;
	min-height: calc(100vh - var(--property-header-min-height-small));
}

@media (--mq-up-to-xsmall) {
	.property-hero {
		display: flex;
		flex-direction: column;
	}
}

@media (--mq-from-small) {
	.property-hero {
		min-height: calc(100vh - var(--property-header-min-height-large));
	}
}

.property-hero::before {
	background-image: linear-gradient(to bottom, var(--color-white), rgb(255 255 255 / 0));
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: 2;
}

@media (--mq-up-to-xsmall) {
	.property-hero__content,
	.property-hero__media {
		width: 100%;
	}
}

.property-hero__media {
	background-color: var(--color-grey);
	overflow: hidden;
	position: relative;
	z-index: 1;
}

@media (--mq-up-to-xsmall) {
	.property-hero__media {
		flex-basis: 100%;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (--mq-from-xsmall) {
	.property-hero__media {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.property-hero__background-media {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.property-hero__background-media__asset {
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.property-hero__background-media__asset {
		object-fit: cover;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		height: 100%;
	}
}

.property-hero__highlights {
	box-sizing: border-box;
	color: var(--color-white);
	overflow: visible;
	position: absolute;
	text-align: center;
	top: 75%;
	left: 0;
	width: 100%;
	z-index: 3;
}

.property-hero__highlights__content {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
}

.property-hero__content {
	color: var(--theme-color);
}

@media (--mq-up-to-xsmall) {
	.property-hero__content {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 1;
	}
}

@media (--mq-from-xsmall) {
	.property-hero__content {
		display: none;
	}
}

.property-hero__title {
	box-sizing: border-box;
	display: block;
	padding: var(--space-xxsmall) var(--space-xsmall);
	width: 100%;
}
