.doormat {
	background-color: var(--theme-color-light);
	color: var(--theme-color-dark);
	font-size: 1rem;
	line-height: 1.31;
	text-align: center;
}

@media (--mq-from-medium) {
	.doormat {
		font-size: 1.375rem;
		line-height: 1.3636;
	}
}

.doormat__title {
	margin-top: -10px !important;
}

@media (--mq-from-wide) {
	.doormat__title {
		margin-bottom: var(--space-small);
	}
}

.doormat__buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-end;
}

.doormat__title + .doormat__buttons,
.doormat__content + .doormat__buttons {
	margin-top: var(--space-xsmall);
}

@media (--mq-from-wide) {
	.doormat__title + .doormat__buttons,
	.doormat__content + .doormat__buttons {
		margin-top: var(--space-small);
	}
}

.doormat__buttons__element {
	margin-top: var(--space-xsmall);
	width: auto;
}

@media (--mq-from-small) {
	.doormat__buttons__element {
		margin-top: 1.875rem;
	}
}

@media (--mq-from-wide) {
	.doormat__buttons__element {
		margin-top: var(--space-small);
	}
}

.doormat__buttons__element:not(:first-child):not(:only-child) {
	margin-top: var(--space-xxsmall);
}

.doormat__buttons__element:not(:only-child) {
	margin-right: calc(var(--space-xxsmall) / 2);
	margin-left: calc(var(--space-xxsmall) / 2);
}

@media (--mq-from-medium) {
	.doormat__buttons__element:not(:only-child) {
		margin-right: calc(var(--space-xlarge) / 2);
		margin-left: calc(var(--space-xlarge) / 2);
	}
}
