.call-to-action.call-to-action--has-attachment {
	padding-bottom: var(--space-small);
	position: relative;
}

@media (--mq-from-small) {
	.call-to-action.call-to-action--has-attachment {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
	}
}

@media (--mq-from-medium) {
	.call-to-action.call-to-action--has-attachment {
		padding-bottom: var(--space-medium);
	}
}

@media (--mq-from-large) {
	.call-to-action.call-to-action--has-attachment {
		padding-bottom: var(--space-large);
	}
}

@media (--mq-from-small) {
	.call-to-action__media {
		flex: auto;
		flex-basis: calc(100% - 80px);
		margin-top: var(--space-small);
		margin-left: -310px;
		position: relative;
		z-index: 1;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.call-to-action__media {
		margin-right: 30px;
	}
}

@media (--mq-from-medium) {
	.call-to-action__media {
		flex-basis: calc(100% - 65px);
		margin-top: var(--space-medium);
		margin-left: -355px;
	}
}

@media (--mq-from-large) {
	.call-to-action__media {
		flex-basis: 830px;
		margin-left: -290px;
	}
}

.call-to-action__block {
	background-color: var(--theme-color);
	box-sizing: border-box;
	color: var(--color-white);
	display: block;
	padding: var(--space-xsmall);
	text-align: center;
	width: 100%;
}

@media (--mq-from-medium) {
	.call-to-action__block {
		padding: var(--space-small);
	}
}

@media (--mq-from-small) {
	.call-to-action--has-attachment .call-to-action__block {
		position: relative;
		width: 340px;
		z-index: 2;
	}
}

@media (--mq-from-medium) {
	.call-to-action--has-attachment .call-to-action__block {
		width: 420px;
	}
}

.call-to-action__title:not(:last-child):not(:only-child),
.call-to-action__content:not(:last-child):not(:only-child) {
	margin-bottom: var(--space-xxsmall);
}

@media (--mq-from-medium) {
	.call-to-action__title:not(:last-child):not(:only-child),
	.call-to-action__content:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-xsmall);
	}
}

.call-to-action__content {
	margin-right: auto;
	margin-left: auto;
	max-width: 380px;
}

.call-to-action__button {
	display: inline-block;
	min-width: 100px;
}

.call-to-action__scribble {
	position: absolute;
	top: var(--space-medium);
	right: 100%;
	transform: translateX(-120px);
	width: 131px;
	height: 129px;
}

@media (--mq-up-to-xlarge) {
	.call-to-action__scribble {
		display: none;
	}
}
