.l-error {
	box-sizing: border-box;
	display: flex;
	padding-top: 2rem;
	padding-bottom: 2rem;
	justify-content: center;
	align-items: center;
	position: absolute;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	min-height: 100%;
}

.l-error__wrapper {
	width: calc(100% - 2.5rem);
	max-width: 500px;
}

.l-error__logo,
.l-error__title {
	display: inline-block;
	margin-right: auto;
	margin-bottom: var(--space-small);
	margin-left: auto;
}

.l-error__title {
	margin-top: 0;
	max-width: 100%;
	height: auto;
}

@media (--mq-up-to-small) {
	.l-error__title {
		max-width: 80%;
	}
}
