.section {
	padding-top: var(--space-small);
	padding-bottom: var(--space-small);
	position: relative;
}

@media (--mq-from-medium) {
	.section {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

@media (--mq-from-large) {
	.section {
		padding-top: var(--space-large);
		padding-bottom: var(--space-large);
	}
}

@media (--mq-up-to-medium) {
	.section.section--xsmall {
		padding-top: var(--space-xsmall);
		padding-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-medium) {
	.section.section--xsmall {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (--mq-from-large) {
	.section.section--xsmall {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (--mq-up-to-medium) {
	.section.section--small {
		padding-top: var(--space-xsmall);
		padding-bottom: var(--space-xsmall);
	}
}

.section.section--theme {
	background-color: var(--theme-color-light);
}
