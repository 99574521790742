.inspiration-quote {
	margin-right: auto;
	margin-left: auto;
	max-width: 420px;
}

@media (--mq-from-medium) {
	.inspiration-quote {
		max-width: 600px;
	}
}

@media (--mq-from-large) {
	.inspiration-quote {
		max-width: 720px;
	}
}
