.l-contact {
	box-sizing: border-box;
	padding-bottom: var(--space-small);
	width: 100%;
}

@media (--mq-from-small) {
	.l-contact {
		display: flex;
		flex-direction: row-reverse;
		padding-right: var(--space-xsmall);
		padding-bottom: var(--space-medium);
		padding-left: var(--space-xsmall);
		justify-content: space-between;
		align-items: center;
	}
}

@media (--mq-from-medium) {
	.l-contact {
		padding-right: 5rem;
		padding-left: 5rem;
	}
}

@media (--mq-from-large) {
	.l-contact {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-bottom: var(--space-large);
		padding-left: 0;
		width: 1280px;
	}
}

@media (--mq-from-small) {
	.l-contact__media,
	.l-contact__content {
		flex-basis: calc((100% - var(--space-small)) / 2);
		max-width: 560px;
	}
}

@media (--mq-from-medium) {
	.l-contact__media,
	.l-contact__content {
		flex-basis: calc((100% - var(--space-medium)) / 2);
	}
}

@media (--mq-from-large) {
	.l-contact__media,
	.l-contact__content {
		flex-basis: calc((100% - var(--space-large)) / 2);
	}
}

@media (--mq-from-small) {
	.l-contact__media {
		margin-left: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.l-contact__media {
		margin-left: var(--space-medium);
	}
}

@media (--mq-from-large) {
	.l-contact__media {
		margin-left: var(--space-large);
	}
}

@media (--mq-up-to-small) {
	.l-contact__content {
		box-sizing: border-box;
		padding-top: var(--space-small);
		padding-right: var(--space-xsmall);
		padding-left: var(--space-xsmall);
		width: 100%;
	}
}

.l-contact__content__text {
	text-align: center;
}
