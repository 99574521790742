@media (--mq-up-to-small) {
	.hotspotsmap {
		display: none;
	}
}

@media (--mq-from-small) {
	.hotspotsmap {
		display: block;
		width: 100%;
		height: calc(100vh - var(--property-header-min-height-large));
	}
}

.hotspotsmap__popup {
	color: white;
	line-height: 16px;
}

.hotspotsmap__popup .tag__label {
	transform: translateY(1px) !important;
}

.hotspotsmap__pushpin {
	display: block;
	position: relative;
	width: 0;
	height: 0;
}

.hotspotsmap__pushpin::before {
	background-image: url("../images/pushpin.png");
	background-size: cover;
	content: "";
	cursor: pointer;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translate3d(-50%, 0, 0);
	width: 60px;
	height: 60px;
}
