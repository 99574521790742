.section-title {
	color: var(--theme-color-dark);
	margin-bottom: var(--space-xsmall);
	text-align: center;
	width: 100%;
}

@media (--mq-from-large) {
	.section-title {
		margin-bottom: var(--space-small);
	}
}
