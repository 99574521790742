@media (min-width: 768px) {
	.media-block-group {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}

.media-block-group__item {
	display: block;
	flex-basis: 50%;
}

@media (max-width: 767px) {
	.media-block-group__item:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-small);
	}
}

@media (min-width: 768px) {
	.media-block-group__item:not(:last-child):not(:only-child) {
		margin-right: var(--space-small);
	}
}

@media (min-width: 1024px) {
	.media-block-group__item:not(:last-child):not(:only-child) {
		margin-right: var(--space-medium);
	}
}

@media (min-width: 1680px) {
	.media-block-group__item:not(:last-child):not(:only-child) {
		margin-right: var(--space-large);
	}
}

@media (min-width: 768px) {
	.media-block-group--has-wrapper .media-block-group__item:not(:last-child):not(:only-child) {
		margin-right: var(--space-xsmall);
	}
}

@media (min-width: 1024px) {
	.media-block-group--has-wrapper .media-block-group__item:not(:last-child):not(:only-child) {
		margin-right: var(--space-small);
	}
}

@media (min-width: 1680px) {
	.media-block-group--has-wrapper .media-block-group__item:not(:last-child):not(:only-child) {
		margin-right: var(--space-small);
	}
}
