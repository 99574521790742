.tag {
	appearance: none;
	background: none;
	border: 1px solid;
	border-radius: 1.0625rem;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-grey);
	display: inline-block;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	padding: 0.5625rem;
	text-align: center;
	text-decoration: none;
	user-select: none;
}

.tag.tag--small {
	border-radius: 0.8125rem;
	font-size: 0.75rem;
	line-height: 1.25;
	padding-top: 0;
	padding-bottom: 0;
}

.tag.tag--dark {
	color: var(--theme-color-dark);
}

.tag--small .tag__label {
	display: inline-block;
	transform: translateY(-1px);
	width: 100%;
}
