.gallery {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.gallery[data-aspect-ratio] {
	overflow: hidden;
	padding-top: 100%;
}

.gallery[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

.gallery[data-aspect-ratio="1:2"] {
	padding-top: 200%;
}

.gallery[data-aspect-ratio="2:1"] {
	padding-top: 50%;
}

.gallery[data-aspect-ratio="2:3"] {
	padding-top: calc(100% * (3 / 2));
}

.gallery[data-aspect-ratio="3:2"] {
	padding-top: calc(100% * (2 / 3));
}

.gallery[data-aspect-ratio="3:4"] {
	padding-top: calc(100% * (4 / 3));
}

.gallery[data-aspect-ratio="4:3"] {
	padding-top: calc(100% * (3 / 4));
}

.gallery[data-aspect-ratio="16:9"] {
	padding-top: calc(100% * (9 / 16));
}

.gallery[data-aspect-ratio="21:9"] {
	padding-top: calc(100% * (9 / 21));
}

.gallery__item {
	display: none;
	margin: 0;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.gallery__item.is-active {
	opacity: 1;
	z-index: 10;
}

.gallery__item.is-previous,
.gallery__item.is-next,
.gallery__item.is-active {
	display: block;
}

.gallery__item:hover {
	cursor: pointer;
}

.gallery__navigation {
	display: flex;
	opacity: 0;
	position: absolute;
	bottom: var(--space-xxsmall);
	left: 50%;
	transform: translate(-50%, 0);
	transition: opacity 96ms linear;
	z-index: 10;
}

.gallery.is-resolved .gallery__navigation {
	opacity: 1;
}

.gallery__button {
	appearance: none;
	background: none;
	background-color: var(--color-white);
	border: 0;
	border-radius: 50%;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	margin-right: 5px;
	margin-left: 5px;
	opacity: 0.5;
	padding: 0;
	position: relative;
	transition: opacity 240ms linear;
	width: 10px;
	height: 10px;
}

.gallery__button:focus,
.gallery__button:hover {
	opacity: 0.8;
	outline: 0;
}

.gallery__button:hover {
	cursor: pointer;
}

.gallery__button.is-active {
	opacity: 1;
}
