.wrapper {
	box-sizing: border-box;
	padding-right: var(--space-xsmall);
	padding-left: var(--space-xsmall);
	width: 100%;
}

@media (--mq-from-medium) {
	.wrapper {
		padding-right: var(--space-small);
		padding-left: var(--space-small);
	}
}

@media (--mq-from-large) {
	.wrapper {
		box-sizing: border-box;
		margin-right: auto;
		margin-left: auto;
		max-width: 1440px;
	}
}

.wrapper.wrapper--xsmall {
	margin-right: auto;
	margin-left: auto;
	max-width: 580px;
}

@media (--mq-from-large) {
	.wrapper.wrapper--xsmall {
		max-width: 580px;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--small {
		padding-right: 11.375rem;
		padding-left: 11.375rem;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--small {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 675px;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--medium {
		padding-right: 6.25rem;
		padding-left: 6.25rem;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--medium {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 675px;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--large {
		padding-right: 5rem;
		padding-left: 5rem;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--large {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 960px;
	}
}
