.responsive-logo-site-footer {
	color: var(--color-white);
	display: block;
	transition: color 96ms linear;
	width: 60px;
	height: 24px;
}

@media (--mq-from-medium) {
	.responsive-logo-site-footer {
		width: 106px;
		height: 60px;
	}
}

.responsive-logo-site-footer__asset {
	color: currentColor;
	display: none;
	width: 100%;
	height: 100%;
}

@media (--mq-up-to-medium) {
	.responsive-logo-site-footer__asset.responsive-logo-site-footer__asset--mobile {
		display: block;
	}
}

@media (--mq-from-medium) {
	.responsive-logo-site-footer__asset.responsive-logo-site-footer__asset--desktop {
		display: block;
	}
}

.responsive-logo-site-footer__asset svg {
	display: block;
	width: 100%;
	height: 100%;
}

.responsive-logo-site-footer__asset svg * {
	fill: currentColor;
}
