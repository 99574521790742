.responsive-logo-site-header {
	color: var(--color-black);
	display: block;
	transition: color 96ms linear;
	width: 115px;
	height: 35px;
}

@media (--mq-from-small) {
	.responsive-logo-site-header {
		width: 124px;
		height: 70px;
	}
}

.responsive-logo-site-header__asset {
	color: currentColor;
	display: none;
	width: 100%;
	height: 100%;
}

@media (--mq-up-to-small) {
	.responsive-logo-site-header__asset.responsive-logo-site-header__asset--mobile {
		display: block;
	}
}

@media (--mq-from-small) {
	.responsive-logo-site-header__asset.responsive-logo-site-header__asset--desktop {
		display: block;
	}
}

.responsive-logo-site-header__asset svg {
	display: block;
	width: 100%;
	height: 100%;
}

.responsive-logo-site-header__asset svg * {
	fill: currentColor;
}
