.offer-call-to-action {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin-right: auto;
	margin-left: auto;
	padding: var(--space-small) var(--space-xxsmall);
	justify-content: center;
	position: relative;
	text-align: center;
	width: 100%;
	min-height: calc(100vw - 40px);
}

@media (min-width: 405px) {
	.offer-call-to-action {
		max-width: 365px;
		min-height: 365px;
	}
}

@media (--mq-from-wide) {
	.offer-call-to-action {
		max-width: 467px;
		min-height: 467px;
	}
}

.offer-call-to-action__link {
	display: inline-block;
	margin-top: var(--space-xsmall);
	margin-right: auto;
	margin-left: auto;
	text-decoration: none;
	transform: translateZ(0);
	transition: transform 240ms ease-in-out;
	max-width: 350px;
	height: var(--space-medium);
}

.offer-call-to-action__link:focus,
.offer-call-to-action__link:hover {
	transform: translateY(calc(var(--space-xxsmall) * -1)) translateZ(0);
}

@media (--mq-from-small) {
	.offer-call-to-action__link {
		margin-top: var(--space-small);
	}
}

.offer-call-to-action__link__curve {
	font-size: 2.1875rem;
	text-transform: uppercase;
	width: 100%;
}

.offer-call-to-action__icon {
	color: var(--theme-color);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	width: 100%;
	height: 100%;
	z-index: -1;
}

.offer-call-to-action__icon__item {
	width: 100%;
	height: 100%;
}
