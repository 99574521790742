.quote-a {
	color: var(--theme-color);
	text-align: center;
}

@media (--mq-from-small) {
	.quote-a {
		max-width: 50%;
	}
}

@media (--mq-from-medium) {
	.quote-a {
		max-width: 33.333%;
	}
}

.quote-a.quote-a--left {
	text-align: left;
}

.quote-a.quote-a--right {
	text-align: right;
}
