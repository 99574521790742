@media (--mq-from-small) {
	.inspiration-grid {
		display: flex;
		justify-content: space-between;
	}
}

@media (--mq-from-small) {
	.inspiration-grid__column {
		flex-basis: calc(50% - (var(--space-small) / 2));
	}
}

@media (--mq-from-medium) {
	.inspiration-grid__column {
		flex-basis: calc(50% - (var(--space-medium) / 2));
	}
}

@media (--mq-from-large) {
	.inspiration-grid__column {
		flex-basis: calc(50% - (var(--space-large) / 2));
	}
}

@media (--mq-up-to-small) {
	.inspiration-grid__column:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-small);
	}
}

.inspiration-grid__item:not(:last-child):not(:only-child) {
	margin-bottom: var(--space-small);
}

@media (--mq-from-medium) {
	.inspiration-grid__item:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-medium);
	}
}

@media (--mq-from-large) {
	.inspiration-grid__item:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-large);
	}
}
