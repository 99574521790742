.area-description h1,
.area-description h2,
.area-description h3,
.area-description h4,
.area-description h5,
.area-description h6 {
	color: var(--theme-color-dark);
}

.t-pink .area-description h1,
.t-pink .area-description h2,
.t-pink .area-description h3,
.t-pink .area-description h4,
.t-pink .area-description h5,
.t-pink .area-description h6 {
	color: var(--theme-color);
}
