.menu {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (--mq-from-small) {
	.menu {
		display: flex;
		justify-content: center;
	}
}

.menu__item {
	color: var(--color-black);
}

@media (--mq-from-small) {
	.menu__item {
		text-align: center;
	}
}

@media (--mq-from-small) {
	.menu__item:not(:first-child):not(:only-child) {
		margin-left: 0.9375rem;
	}
}

@media (--mq-up-to-small) {
	.menu__item:not(:last-child):not(:only-child) {
		margin-bottom: 0.8125rem;
	}
}

@media (--mq-from-small) {
	.menu__item:not(:last-child):not(:only-child) {
		margin-right: 0.9375rem;
	}
}

.menu__link {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	color: currentColor;
	font-size: 1.25rem;
	line-height: normal;
	text-decoration: none;
	text-transform: uppercase;
}

.menu--medium .menu__link {
	font-size: 1rem;
	line-height: 1.31;
}

.menu__item--active-state-te-huur .menu__link:focus,
.menu__item--active-state-te-huur .menu__link:hover,
.menu__item--active-state-te-huur.current-menu-item .menu__link,
.menu__item--active-state-te-huur.current-property-parent .menu__link {
	background-image: url("../images/active-state-te-huur.svg");
}

.menu__item--active-state-te-koop .menu__link:focus,
.menu__item--active-state-te-koop .menu__link:hover,
.menu__item--active-state-te-koop.current-menu-item .menu__link,
.menu__item--active-state-te-koop.current-property-parent .menu__link {
	background-image: url("../images/active-state-te-koop.svg");
}

.menu__item--active-state-verkocht-verhuurd .menu__link:focus,
.menu__item--active-state-verkocht-verhuurd .menu__link:hover,
.menu__item--active-state-verkocht-verhuurd.current-menu-item .menu__link,
.menu__item--active-state-verkocht-verhuurd.current-property-parent .menu__link {
	background-image: url("../images/active-state-verkocht-verhuurd.svg");
}

.menu__item--active-state-secondary-a .menu__link:focus,
.menu__item--active-state-secondary-a .menu__link:hover,
.menu__item--active-state-secondary-a.current-menu-item .menu__link,
.menu__item--active-state-secondary-a.current-property-parent .menu__link {
	background-image: url("../images/active-state-secondary-a.svg");
}

.menu__item--active-state-secondary-b .menu__link:focus,
.menu__item--active-state-secondary-b .menu__link:hover,
.menu__item--active-state-secondary-b.current-menu-item .menu__link,
.menu__item--active-state-secondary-b.current-property-parent .menu__link {
	background-image: url("../images/active-state-secondary-b.svg");
}
