.social-links {
	color: currentColor;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: flex-start;
	align-items: center;
}

.social-links__item {
	flex-basis: 20px;
	flex-grow: 0;
	flex-shrink: 0;
}

.social-links__item:not(:last-child) {
	margin-right: 0.9375rem;
}

.social-links__link {
	color: currentColor;
	display: block;
	position: relative;
	transition: color 96ms linear;
	width: 20px;
	height: 20px;
}

.social-links__link:hover {
	color: var(--color-darkened-green);
}

.social-links__link > svg {
	display: block;
	fill: currentColor;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
