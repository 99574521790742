@media (--mq-from-small) {
	.inspiration-side-by-side {
		display: flex;
		justify-content: space-between;
	}
}

@media (--mq-from-small) {
	.inspiration-side-by-side.inspiration-side-by-side--left {
		flex-direction: row-reverse;
	}
}

@media (--mq-from-small) {
	.inspiration-side-by-side__column {
		flex-basis: calc((100% - var(--space-small)) / 2);
	}
}

@media (--mq-from-medium) {
	.inspiration-side-by-side__column {
		flex-basis: calc((100% - var(--space-medium)) / 2);
	}
}

@media (--mq-from-large) {
	.inspiration-side-by-side__column {
		flex-basis: calc((100% - var(--space-large)) / 2);
	}
}

@media (--mq-up-to-small) {
	.inspiration-side-by-side__column + .inspiration-side-by-side__column {
		margin-top: var(--space-small);
	}
}

@media (--mq-up-to-small) {
	.inspiration-side-by-side__column.inspiration-side-by-side__column--quote {
		margin-right: auto;
		margin-left: auto;
		max-width: 420px;
	}
}
