.slide {
	background-color: var(--color-grey);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-top: calc(var(--space-small) + var(--space-small));
	padding-bottom: calc(var(--space-small) + var(--space-small));
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: calc(100vw / 72 * 27);
}

@media (--mq-from-medium) {
	.slide {
		padding-top: calc(var(--space-medium) + var(--space-small));
		padding-bottom: calc(var(--space-medium) + var(--space-small));
	}
}

@media (--mq-from-wide) {
	.slide {
		padding-top: calc(var(--space-large) + var(--space-small));
		padding-bottom: calc(var(--space-large) + var(--space-small));
	}
}

@media (--mq-from-large) {
	.slide {
		padding-top: calc(var(--space-xlarge) + var(--space-small));
		padding-bottom: calc(var(--space-xlarge) + var(--space-small));
	}
}

.slide__content {
	color: var(--color-white);
	position: relative;
	text-align: center;
	z-index: 1;
}

.slide__background {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	width: auto;
	height: 100%;
	z-index: 0;
}

@supports (object-fit: cover) {
	.slide__background {
		object-fit: cover;
		width: 100%;
	}
}
