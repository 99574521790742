:root {
	--color-black: #000000;
	--color-blue: #2e3192;
	--color-gold: #ffdf5d;
	--color-grey: #dbdbdb;
	--color-pink: #f8aa9e;
	--color-green: #8ccfb7;
	--color-white: #ffffff;
	--color-yellow: #ffdf5d;
	/*  */
	--color-darkened-green: #007274;
	--color-darkened-pink: #d57769;
	/*  */
	--color-lightened-green: rgb(140 207 183 / 0.19);
	--color-lightened-pink: rgb(248 170 158 / 0.1);
	/*  */
	--color-transparent-green: rgb(140 207 182 / 0);
	--color-transparent-white: rgb(255 255 255 / 0);
	/*  */
	--font-regular: "National Regular", "Helvetica Neue", sans-serif;
	/*  */
	--property-header-min-height-small: 50px;
	--property-header-min-height-large: 90px;
	/*  */
	--site-header-height-small: 50px;
	--site-header-height-medium: 160px;
	--site-header-height-large: 220px;
	/*  */
	--space-xxsmall: 10px;
	--space-xsmall: 20px;
	--space-small: 40px;
	--space-medium: 65px;
	--space-large: 80px;
	--space-xlarge: 100px;

	/* Default theming colors  */
	--theme-color: var(--color-green);
	--theme-color-dark: var(--color-darkened-green);
	--theme-color-light: var(--color-lightened-green);
}

body.t-green {
	--theme-color: var(--color-green);
	--theme-color-dark: var(--color-darkened-green);
	--theme-color-light: var(--color-lightened-green);
}

body.t-pink {
	--theme-color: var(--color-pink);
	--theme-color-dark: var(--color-darkened-pink);
	--theme-color-light: var(--color-lightened-pink);
}
