.text-row {
	position: relative;
	text-align: center;
}

@media (--mq-from-small) {
	.text-row {
		display: flex;
		align-items: center;
	}
}

.text-row + .text-row {
	margin-top: var(--space-small);
}

@media (--mq-from-small) {
	.text-row + .text-row {
		margin-top: var(--space-large);
	}
}

@media (--mq-from-small) {
	.text-row:nth-of-type(even) {
		flex-direction: row-reverse;
	}
}

.text-row__title {
	color: var(--theme-color-dark);
}

@media (--mq-up-to-small) {
	.text-row__title {
		margin-bottom: var(--space-xsmall);
	}
}

.t-pink .text-row__title {
	color: var(--theme-color);
}

@media (--mq-from-small) {
	.text-row__content,
	.text-row__title {
		flex-basis: 50%;
	}
}

@media (--mq-from-small) {
	.text-row__container {
		box-sizing: border-box;
		display: block;
		padding-right: var(--space-small);
		padding-left: var(--space-small);
	}
}

.text-row__scribble {
	color: var(--theme-color);
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translate(var(--space-small), -50%);
	width: 190px;
	height: 146px;
}

@media (--mq-up-to-xlarge) {
	.text-row__scribble {
		display: none;
	}
}
